<script setup lang="ts">
import IconButton from "./IconButton.vue";

const emit = defineEmits(["close"]);

function closeModal() {
    console.log("emitting close modal")
    emit("close");
}
</script>

<template>
  <Teleport to="body">
    <div class="modal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <div class="title" v-if="$slots.title">
          <slot name="title" />
        </div>
        <IconButton type="close" @click="closeModal" variant="link" color="gray" class="close" />
        <slot name="body"/>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  background-color: $color-backdrop;
}
.modal-content {
  position: relative;
  max-width: 600px;
  width: 100%;
  padding: spacing(4);
  margin: 0 spacing(3);

  background-color: $color-dark;
  border-radius: $border-radius;
}
.title {
  font-size: $font-size-3;
  font-weight: 700;
  margin-bottom: spacing(2);
}
.close {
  position: absolute;
  top: spacing(3);
  right: spacing(3);
}
</style>