<template>
  <span>
  <UTooltip :text="buttonTooltip" v-if="tooltip">
    <UButton
      square
      :icon="iconName"
      :size="buttonSize"
      :color="color"
      :variant="buttonVariant"
      :trailing="false"
      :to="to"
      @click="handleClick"
      ><slot></slot>    
    </UButton>
  </UTooltip>
  <UButton v-else
    :icon="iconName"
    :size="buttonSize"
    :color="color"
    :variant="buttonVariant"
    :square="square"
    :trailing="false"
    :to="to"
    @click="handleClick"
    ><slot></slot>    
  </UButton>
</span>
</template>

<script setup lang="ts">
const emit = defineEmits(["click"]);

const handleClick = (event: any) => {
    emit('click', event);
};

const props = defineProps({
  to: {
    type: Object,    
  },
  type: {
    type: String,
    description:
      "The type of button to display (ex: edit, delete, close, open)",
  },
  icon: {
    type: String,
    description: "The exact icon to display on the button",
  },
  size: {
    type: String,
    default: "sm",
  },
  color: {
    type: String,
    default: "primary",
  },
  square: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: "solid",
  },
  tooltip: {
    type: String,
    default: "",
  },
});

const buttonSize: any = computed(() => props.size);

const buttonTooltip = computed(() => props.tooltip);

const buttonVariant: any = computed(() => {
  switch (props.variant) {
    case "solid":
      return "solid";
    case "outline":
      return "outline";
    case "link":
      return "link";
    default:
      return "normal";
  }
});
const iconName = computed(() => {
  if (props.icon) {
    return props.icon;
  }
  switch (props.type) {
    case "edit":
      return "i-heroicons-pencil-square";
    case "delete":
      return "i-heroicons-trash";
    case "settings":
      return "i-heroicons-cog";
    case "add":
      return "i-heroicons-plus-circle";
    case "people":
      return "i-heroicons-user-circle";
    case "extract":
      return "i-heroicons-bars-arrow-down";
    case "fork":
      return "i-heroicons-share";
    case "close":
      return "i-heroicons-x-mark";
    case "open":
      return "i-heroicons-magnifying-glass";
    case "launch":
      return "i-heroicons-rocket-launch";
    default:
      return "i-heroicons-hashtag";
  }
});
</script>
