<template>
    <div :id="id" :class="classes">
        <SectionHeading :text="heading" />
        <slot></slot>
    </div>
 </template>
 
 <script setup>
 const props = defineProps(['id', 'class', 'heading'])
 const classes = computed(() => `scroll-my-28 scroll-margin-40 mt-20 ${props.class}`);
 </script>
 
 <style scoped>
 /* CSS specific to this page */
 </style>
 