<template>
    <h3 :class="classes">{{ text }}</h3>   
</template>

<script setup>
const props = defineProps(['class', 'text'])
const classes = computed(() => `mb-10 text-3xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-white ${props.class}`);
</script>

<style scoped>
/* CSS specific to this page */
</style>
